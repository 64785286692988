import React, { useState } from "react";
import baseUrl from "../api";
import axios from "axios";
import "../style.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Alert from "@mui/material/Alert";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  WindowSharp,
} from "@mui/icons-material";
import {
  PROD_TEXT,
  cardtitles,
  consultation,
  selectedcenter,
  doctorImages,
} from "../constant";
import rafiki from "../assets/rafiki.webp";
import Rightsidecard from "./Rightsidecard";
import PheonixButton from "./PheonixButton";
import rightclick from "../assets/rightclick.webp";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { setTimeout } from "timers/promises";
import dayjs, { Dayjs } from "dayjs";
interface Errors {
  Email: boolean;
  PhoneNumber: boolean;
  [key: string]: boolean;
}
type Doctor = {
  id: number;
  imageUrl: string;
  name: string;
  qualifications: string;
  specializations: string;
  consultationFee: string;
  center?: string;
};
const Customcard: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedData, setSelectedData] = useState<any>({});
  const [isImagesOpen, setIsImagesOpen] = useState([true, false, false, false]);
  const [location, setlocation] = useState("");
  const [doctorname, setDoctorname] = useState("");
  const [specialist, setSpecialist] = useState("");
  const [selectedImages, setSelectedImages] = useState<(string | null)[]>([
    null,
    null,
    null,
  ]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [confirmed, setConfirmed] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [completed, setCompleted] = useState(new Set());
  let doctoremail = PROD_TEXT.doctor_email;
  let doctorphone = PROD_TEXT.doctor_phone;
  const [errors, setErrors] = useState<Errors>({
    Email: false,
    PhoneNumber: false,
  });
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirm = () => {
    const requiredFields = [
      "Name",
      "Age",
      "Email",
      "PhoneNumber",
      "Date",
      "Time",
    ];
    const allFieldsFilled = requiredFields.every((field) => formData[field]);
    const missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        missingFields.push(field);
      }
    });

    let errorMessage = PROD_TEXT.errormsg;

    if (!allFieldsFilled) {
      errorMessage += requiredFields
        .filter((field) => !formData[field])
        .join(", ");
      setalertmessage(errorMessage);
      handleSnackbarOpen();
      return;
    } else if (errors.Email || errors.PhoneNumber) {
      setalertmessage(PROD_TEXT.alertmsg);
      handleSnackbarOpen();
      return;
    }
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setConfirmed(true);
    setalertmessage(PROD_TEXT.confirmmsg);
    handleSnackbarOpen();
  };
  const handleReset = () => {
    setFormData({});
    setalertmessage(PROD_TEXT.resetmsg);
    handleSnackbarOpen();
  };
  const handleemail = async () => {
    const query = `
        mutation SendEmail (
            $patient_email: String
            $doctor_email: String
            $doctorName: String
            $patientName: String
            $age: String
            $location: String
            $patient_phone: String
            $doctor_phone: String
            $specialist: String
            $timing: String
            $date: String
        ) {
            sendEmail(
                patient_email: $patient_email
                doctor_email: $doctor_email
                doctorName: $doctorName
                patientName: $patientName
                age: $age
                location: $location
                patient_phone: $patient_phone
                doctor_phone: $doctor_phone
                specialist: $specialist
                timing: $timing
                date: $date
            ) {
                success
                message
            }
        }
    `;

    const variables = {
      patient_email: formData.Email,
      doctor_email: doctoremail,
      doctorName: doctorname,
      patientName: formData.Name,
      age: formData.Age,
      location: location,
      patient_phone: formData.PhoneNumber,
      doctor_phone: doctorphone,
      specialist: specialist,
      timing: "",
      date: formData.Date,
    };
    const { Time } = formData;
    const [time, ampm] = Time.split(" ");
    variables.timing = `${time} ${ampm}`;

    try {
      const response = await axios.post(baseUrl, {
        query,
        variables,
      });
      const { success, message } = response.data.data.sendEmail;
      if (success) {
        setalertmessage(PROD_TEXT.emailmsg);
        handleSnackbarOpen();
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setalertmessage(PROD_TEXT.emailfail);
        handleSnackbarOpen();
      }
    } catch (error) {
      console.error(error);
      setalertmessage(PROD_TEXT.emailfail);
      handleSnackbarOpen();
    }
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepClick = (step: any) => {
    setActiveStep(step);
    if (step === 0) {
      setIsImagesOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        newOpen[1] = true;
        return newOpen;
      });
    }
  };
  const toggleImages = (index: number) => {
    setIsImagesOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };
  const [imageZeroClicked, setImageZeroClicked] = useState(false);
  const [imageOneClicked, setImageOneClicked] = useState(false);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };
  const handleBack = () => {
    const prevIndex = activeStep - 1;
    setActiveStep(prevIndex);
    if (prevIndex >= 0 && !isImagesOpen[prevIndex]) {
      toggleImages(prevIndex);
    }
  };
  const handleCenterSelect = (centerName: string) => {
    setSelectedCenter(centerName);
    
    const doctorsForCenter = doctorImages.filter(
      (doctor) => doctor.center === centerName || doctor.center === "all"
    );
  
    setFilteredDoctors(doctorsForCenter);
  };

  const getCardContent = (type: any[], index: number) => (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      paddingTop={3}
    >
      {type.map((item) => (
        <Grid item key={item.id}>
          <div
            onClick={(e) => {
              handleNext();
              handleImageClick(index, item.imageUrl);
            }}
            style={{
              justifyItems: "center",
              flexDirection: "column",
              width: index === 2 ? "180px" : index === 1 ? "183.33px" : "200px",
              borderRadius: "8px",
              height: index === 2 ? "220px" : index === 1 ? "202px" : "200px",
              border:
                selectedImages[index] === item.imageUrl
                  ? "2px solid rgba(120, 174, 55, 1)"
                  : "1px solid rgba(120, 174, 55, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              cursor: "pointer",
              backgroundColor:
                selectedImages[index] === item.imageUrl ? "#EDFFCC" : "#FFFFFF",
              marginBottom: "10px",
            }}
          >
            <CardMedia
              component="img"
              src={item.imageUrl}
              alt={item.name}
              sx={{
                height: index === 2 ? "55%" : "119px",
                width: index === 2 ? "50%" : "109px",
                borderRadius: index === 2 ? "90px" : "12px",
                marginTop: "10px",
              }}
              onClick={() => toggleImages(index)}
            />
            <CardContent
              style={{
                fontSize:
                  selectedImages[index] === item.imageUrl ? "14px" : "13px",
                fontWeight:
                  selectedImages[index] === item.imageUrl ? "700" : "500",
              }}
            >
              {index === 2 ? (
                <>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize:
                        selectedImages[index] === item.imageUrl
                          ? "14px"
                          : "13px",
                      fontWeight:
                        selectedImages[index] === item.imageUrl ? "700" : "500",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2">{item.qualifications}</Typography>
                  <Typography variant="body2">
                    {item.specializations}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    fontSize:
                      selectedImages[index] === item.imageUrl ? "14px" : "13px",
                    fontWeight:
                      selectedImages[index] === item.imageUrl ? "700" : "500",
                  }}
                >
                  {item.modeofconsultation || item.centername}
                </Typography>
              )}
            </CardContent>
          </div>
        </Grid>
      ))}
    </Grid>
  );
  const formFields = [
    {
      label: "Full Name",
      placeholder: "Enter your name",
      id: "Name",
      type: "text",
    },
    { label: "Age", placeholder: "Enter your age", id: "Age", type: "number" },
    {
      label: "Email",
      placeholder: "Enter your email",
      id: "Email",
      type: "email",
    },
    {
      label: "Phone Number",
      placeholder: "Enter your phone number",
      id: "PhoneNumber",
      type: "tel",
    },
    {
      label: "Share your message",
      placeholder: "Enter your message",
      id: "Message",
      type: "text",
      multiline: true,
      rows: 4,
    },
  ];
  const formFieldsWithDateAndTime = [
    {
      label: "Full Name",
      placeholder: "Enter your name",
      id: "Name",
      type: "text",
    },
    { label: "Age", placeholder: "Enter your age", id: "Age", type: "number" },
    {
      label: "Email",
      placeholder: "Enter your email",
      id: "Email",
      type: "email",
    },
    {
      label: "Phone Number",
      placeholder: "Enter your phone number",
      id: "PhoneNumber",
      type: "tel",
    },
    {
      label: "Select your Date",
      placeholder: "Select date",
      id: "Date",
      type: "date",
      min: getCurrentDate(),
    },
    {
      label: "Select your slots timing",
      placeholder: "Select time",
      id: "Time",
      type: "time",
    },
    {
      label: "Share your message",
      placeholder: "Enter your message",
      id: "Message",
      type: "text",
      multiline: true,
      rows: 4,
    },
  ];
  const formFieldsToUse = selectedImages[0]
    ? formFieldsWithDateAndTime
    : formFields;
    const [selectedCenter, setSelectedCenter] = useState<string | null>(null);
    const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>([]);
    
 
        const handleImageClick = (index: number, image: string | null) => {
    if (
      index === 0 ||
      (index === 1 && imageZeroClicked) ||
      (index === 2 && imageZeroClicked && imageOneClicked)
    ) {
      setSelectedImages((prevState) => {
        const newSelectedImages = [...prevState];
        newSelectedImages[index] = image;
        return newSelectedImages;
      });
      if (index === 0) {
        setImageZeroClicked(true);
        setSelectedData({ modeofconsultation: PROD_TEXT.title1 });
      } else if (index === 1) {
        setImageOneClicked(true);
        setSelectedData({ centername: PROD_TEXT.select_center });
        const selectedlocation = selectedcenter.find(
          (centre) => centre.imageUrl === image
        );
        if (selectedlocation) {
          setlocation(selectedlocation?.centername);
          handleCenterSelect(selectedlocation.centername);
      }
        else {
          setlocation("");
        }
      } else if (index === 2) {
        setSelectedData({ doctorname: doctorImages[0].name });
        const selecteddoctor = filteredDoctors.find(
          (doctor) => doctor.imageUrl === image
        );
        if (selecteddoctor) {
          setDoctorname(selecteddoctor.name);
          setSpecialist(selecteddoctor.specializations);
        } else {
          setDoctorname("");
          setSpecialist("");
        }
      }
    }
  };
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    switch (id) {
      case "Name":
        const filteredName = value.replace(/[^A-Za-z\s]/g, "");
        setFormData((prevData) => ({ ...prevData, [id]: filteredName }));
        break;
      case "Age":
        const filteredValue = value.replace(/\D/g, "");
        setFormData((prevData) => ({ ...prevData, [id]: filteredValue }));
        break;
      default:
        setFormData((prevData) => ({ ...prevData, [id]: value }));
        break;
    }
  };
  const defaultTime = dayjs().set("hour", 9).set("minute", 0);

  const handleTimeChange = (newTime: dayjs.Dayjs | null) => {
    if (newTime) {
      const formattedTime = newTime.format("hh:mm A");
      setFormData((prevData) => ({ ...prevData, Time: formattedTime }));
    } else {
    }
  };
  const handleBlur = (event: any) => {
    const { id, value } = event.target;
    let newErrors = { ...errors };

    switch (id) {
      case "Email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        newErrors.Email = !emailRegex.test(value);
        setErrors(newErrors);
        break;
      case "PhoneNumber":
        const phoneRegex = /^\d{10}$/;
        newErrors.PhoneNumber = !phoneRegex.test(value);
        setErrors(newErrors);
        break;
      default:
        break;
    }
  };
  const clearErrorOnFocus = (fieldId: any) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldId]: false,
    }));
  };

  return (
    <Box
      style={{
        backgroundColor: "#EDFFCC",
        paddingLeft: isMobile ? "0" : "50px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CssBaseline />
        <div style={{ textAlign: "center" }}>
          <Typography
            style={{
              display: "flex",
              marginBottom: "30px",
              fontWeight: 700,
              fontFamily: "Roboto",
              fontSize: isSmallScreen ? "32px" : "48px",
              color: "#000000",
              marginTop: isSmallScreen ? "10%" : "10%",
            }}
          >
            {PROD_TEXT.CONTENT}
          </Typography>
        </div>
      </Box>
      <Box sx={{ display: "flex", paddingLeft: isMobile ? "10px" : "30px" }}>
        <Grid container spacing={2} justifyContent="space-around">
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            style={{
              marginTop: "10px",
              maxHeight: confirmed ? "258px" : "2500px",
              marginLeft: isMobile ? "5px" : "",
            }}
          >
            {[0, 1, 2, 3].map((index) => (
              <Step key={index} completed={activeStep > index}>
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "red",
                    },
                    "& .MuiStepConnector-root.Mui-completed": {
                      color: "green",
                    },

                    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed":
                      {
                        color: "green",
                      },
                    "& .css-4ff9q7.Mui-completed": {
                      color: "green",
                    },
                  }}
                  StepIconProps={{
                    style: {
                      color:
                        activeStep === index
                          ? "rgba(120, 174, 55, 1)"
                          : "default-color",
                    },
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">
                      {cardtitles[index].title}
                    </Typography>
                  </div>
                </StepLabel>
                <StepContent>
                  <Card
                    style={{
                      margin: "10px",
                      width: isMobile ? "270px" : "637px",
                      height:
                        index === 1
                          ? isMobile
                            ? isImagesOpen[index]
                              ? "750px"
                              : "80px"
                            : isImagesOpen[index]
                            ? "315px"
                            : "70px"
                          : index === 2
                          ? isMobile
                            ? isImagesOpen[index] && selectedCenter === "Chennai"
                              ? "100%" : isImagesOpen[index] && selectedCenter === "Pollachi" || selectedCenter === "Tenkasi"
                              ? "800px": "80px"
                            : isImagesOpen[index] && selectedCenter === "Chennai"
                            ? "550px" : isImagesOpen[index] && selectedCenter === "Pollachi" || selectedCenter === "Tenkasi"
                            ? "320px" : "300px"
                          : index === 3
                          ? isImagesOpen[index]
                            ? "980px"
                            : "70px"
                          : isMobile
                          ? isImagesOpen[index]
                            ? "557px"
                            : "80px"
                          : isImagesOpen[index]
                          ? "315px"
                          : "70px",
                    }}
                    onClick={() => {
                      if (index < 3 && !isImagesOpen[index + 1]) {
                        toggleImages(index + 1);
                      }
                    }}
                  >
                    <CardContent>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          onClick={() => toggleImages(index)}
                        ></div>
                        {isImagesOpen[index] && (
                          <>
                            {index === 0 && getCardContent(consultation, index)}
                            {index === 1 &&
                              getCardContent(selectedcenter, index)}
                            {index === 2 &&
                              getCardContent(filteredDoctors, index)}
                            {index === 3 && (
                              <Grid
                                container
                                alignItems="center"
                                justifyContent={
                                  index === 3 && isMobile ? "left" : "center "
                                }
                                paddingTop={3}
                              >
                                <Grid item>
                                  {formFieldsToUse.map((input) => (
                                    <div key={input.id}>
                                      <Typography
                                        variant="body1"
                                        style={{ textAlign: "left" }}
                                      >
                                        {input.label}
                                      </Typography>
                                      <div style={{ width: "325px" }}>
                                        <FormControl
                                          fullWidth={!isSmallScreen}
                                          margin="normal"
                                          variant="outlined"
                                        >
                                          {input.type === "time" ? (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <TimePicker
                                                label="Select your slots timing"
                                                value={
                                                  formData.Time
                                                    ? dayjs(formData.Time)
                                                    : null
                                                }
                                                onChange={handleTimeChange}
                                                ampm
                                              />
                                            </LocalizationProvider>
                                          ) : (
                                            <div>
                                              <OutlinedInput
                                                id={input.id}
                                                type={input.type}
                                                multiline={input.multiline}
                                                placeholder={input.placeholder}
                                                rows={input.rows}
                                                value={formData[input.id] || ""}
                                                required={true}
                                                onChange={handleFormChange}
                                                onBlur={handleBlur}
                                                error={errors[input.id]}
                                                onFocus={() =>
                                                  clearErrorOnFocus(input.id)
                                                }
                                                inputProps={{
                                                  min:
                                                    input.type === "date"
                                                      ? getCurrentDate()
                                                      : undefined,
                                                  style: {
                                                    padding: "12px",
                                                    borderRadius: "4px",
                                                  },
                                                  ...(input.type === "time" && {
                                                    min: "12:30",
                                                  }),
                                                }}
                                                sx={{
                                                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      borderColor:
                                                        "rgba(120, 174, 55, 1)",
                                                      color:
                                                        "rgba(120, 174, 55, 1)",
                                                    },
                                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      borderColor:
                                                        "rgba(120, 174, 55, 1)",
                                                      color:
                                                        "rgba(120, 174, 55, 1)",
                                                    },
                                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                      borderColor:
                                                        "rgba(120, 174, 55, 1)",
                                                      color:
                                                        "rgba(120, 174, 55, 1)",
                                                    },
                                                  width: "100%",
                                                  minWidth: "220px",
                                                }}
                                              />
                                              {errors[input.id] && (
                                                <Typography
                                                  color="error"
                                                  fontSize={"12px"}
                                                >
                                                  {input.label === "Email"
                                                    ? "Please enter a valid email address"
                                                    : "Please enter a 10-digit phone number"}
                                                </Typography>
                                              )}
                                            </div>
                                          )}
                                        </FormControl>
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    style={{
                                      justifyContent: "space-evenly",
                                      flexDirection: isMobile
                                        ? "row"
                                        : "column",
                                      textAlign: "center",
                                      marginTop: isMobile ? "10px" : "70px",
                                      marginBottom: isMobile ? "20px" : "-40px",
                                    }}
                                  >
                                    <PheonixButton
                                      type="reset"
                                      variant="outlined"
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: isMobile ? "-90px" : 0,
                                        textAlign: "left",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontFamily: "Roboto",
                                        color: "rgba(120, 174, 55, 1)",
                                        borderColor: "rgba(120, 174, 55, 1)",
                                        textTransform: "none",
                                        width: isMobile ? "100px" : "109px",
                                      }}
                                      onClick={handleReset}
                                    >
                                      {PROD_TEXT.Reset}
                                    </PheonixButton>
                                    <PheonixButton
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor:
                                          "rgba(120, 174, 55, 1)",
                                        color: "#FFFFFF",
                                        textTransform: "none",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontFamily: "Roboto",
                                        width: isMobile ? "120px" : "189px",
                                      }}
                                      onClick={handleConfirm}
                                    >
                                      {PROD_TEXT.Confirm}{" "}
                                      <img
                                        src={rightclick}
                                        alt="Right Click"
                                        style={{
                                          marginLeft: "20px",
                                          width: "15.44px",
                                          height: "11.44px",
                                          fontSize: "24px",
                                        }}
                                      />
                                    </PheonixButton>
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </div>

                      {index !== 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <PheonixButton
                            onClick={handleBack}
                            style={{
                              backgroundColor: "rgba(120, 174, 55, 1)",
                              color: "#FFFFFF",
                              textTransform: "none",
                              fontSize: "18px",
                              fontWeight: 500,
                              fontFamily: "Roboto",
                            }}
                          >
                            {PROD_TEXT.BACK}
                          </PheonixButton>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <Grid item xs={12} md={6}>
            <Card
              style={{
                width: isMobile ? "auto" : "479px",
                margin: "10px",
                height: "auto",
              }}
            >
              <CardContent style={{ padding: "30px" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    backgroundColor: "#78AE37",
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#000000",
                    fontFamily: "Roboto",
                    padding: "8px 24px",
                    gap: "10px",
                    borderRadius: "8px",
                  }}
                >
                  {PROD_TEXT.right_card}
                </Typography>
              </CardContent>
              <CardContent>
                {selectedImages.slice(0, 3).map((image, index) => (
                  <React.Fragment key={index}>
                    {image && (
                      <div>
                        <Rightsidecard
                          text={
                            index === 0
                              ? PROD_TEXT.consult1
                              : index === 1
                              ? PROD_TEXT.center
                              : PROD_TEXT.selceted_dctr
                          }
                          item={
                            index === 0
                              ? consultation.find(
                                  (item) => item.imageUrl === image
                                )?.modeofconsultation ?? ""
                              : index === 1
                              ? selectedcenter.find(
                                  (item) => item.imageUrl === image
                                )?.centername ?? ""
                              : doctorImages.find(
                                  (item) => item.imageUrl === image
                                )?.name ?? ""
                          }
                        />

                        <hr
                          style={{
                            border: "none",
                            borderTop: "1px solid #ccc",
                            margin: "16px 0",
                            borderTopColor: "rgba(187, 215, 155, 1)",
                          }}
                        />
                      </div>
                    )}
                  </React.Fragment>
                ))}
                {!selectedImages.some((image) => !!image) && (
                  <CardMedia
                    component="img"
                    sx={{
                      height: "272px",
                      textAlign: "center",
                      width: "365.04px",
                      marginTop: isSmallScreen ? "25%" : "20%",
                      marginLeft: "8%",
                      justifyContent: "space-around",
                      alignContent: "center",
                    }}
                    image={rafiki}
                    alt="Person"
                  />
                )}
                {selectedImages[3] && (
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #ccc",
                      margin: "16px 0",
                    }}
                  />
                )}
                {confirmed == true && (
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    spacing={1}
                    style={{ marginTop: "20px" }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        padding: "0 30px 10px 20px",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                      }}
                    >
                      {PROD_TEXT.details}
                    </Typography>
                    {Object.entries(formData).map(([key, value]) => (
                      <Grid item key={key}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            padding: "0 30px 15px 25px",
                          }}
                        >
                          {key}:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ padding: "0 30px 20px 25px" }}
                        >
                          {value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid>
                  <div
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0 30px 20px 25px",
                    }}
                  >
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px solid #ccc",
                        margin: "16px 0",
                        borderTopColor: "rgba(187, 215, 155, 1)",
                      }}
                    />
                    <PheonixButton
                      type="submit"
                      variant="contained"
                      disabled={!confirmed}
                      style={{
                        backgroundColor: confirmed
                          ? "rgba(120, 174, 55, 1)"
                          : "rgba(120, 174, 55, 0.5)",
                        color: "#FFFFFF",
                        width: isMobile ? "230px" : "395px",
                        height: "52px",
                        textAlign: "center",
                        fontSize: isMobile ? "18px" : "24px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleemail}
                      fullWidth
                    >
                      <img
                        src={rightclick}
                        alt="Right Click"
                        style={{
                          marginRight: "20px",
                          width: "15.44px",
                          height: "11.44px",
                          fontSize: "24px",
                        }}
                      />
                      {PROD_TEXT.YesComing}
                    </PheonixButton>
                  </div>
                </Grid>

                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{
                      width: "100%",
                      backgroundColor: "#78AE37",
                      color: "#fff",
                    }}
                  >
                    {alertmessage}
                  </Alert>
                </Snackbar>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Customcard;
