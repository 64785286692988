import React, { useEffect, useState } from "react";
import {  HOMESCREEN, TEXT_MESSAGES } from "../constant";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  CardMedia,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,

} from "@mui/material";

interface TherapyData {
  id: number;
  therapyname: string;
  title: string;
  description: string;
  imageUrl: string;
}
interface TherapiesProps {
  products: TherapyData[]; 
}

const TherapiesCard: React.FC<TherapiesProps> = ({ products }) => {

 useEffect(() => {
      console.log("products:", products);
    }, [products]);

    const theme = useTheme();
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  
    const handleButton = () => {
      navigate("/bookanappointment");
    };
  
    const handleReadMore = (id: number) => {
      setExpanded((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };
  
    const truncateText = (text: string, length: number) => {
      if (text.length <= 400) return text;
      return text.slice(0, 1000) + "...";
    };
  
    // return (
    //   <>
    //     {products.map((product) => (
    //       <Card key={product.id}>
    //         <Typography
    //           variant="h5"
    //           component="div"
    //           gutterBottom
    //           sx={{
    //             fontWeight: 700,
    //             fontSize: isLargeScreen ? "24px" :isMobileSmall ? "18px" :"20px",
    //             textAlign: "center",
    //             marginLeft: isLargeScreen ? "150px" : "0",
    //             mt: isLargeScreen ? 8 : isLaptopSmall ? 7 : 2,
    //           }}
    //         >
    //           {product.therapyname}
    //         </Typography>
    //         <CardMedia
    //           component="img"
    //           height={isLargeScreen ? 520 : "auto"}
    //           image={product.imageUrl}
    //           alt={product.title}
    //           sx={{
    //             display: "block",
    //             margin: "auto",
    //             borderRadius: "20px",
    //             width: "90%",
    //           }}
    //         />
    //         <CardContent>
    //           <div
    //             style={{
    //               display: "flex",
    //               flexDirection: isMobileSmall ? "column" : "row",
    //               justifyContent: "space-between",
    //               marginTop: isMobileSmall? "0": isTabletScreen? "0": "20px",
    //             }}
    //           >
    //             <Typography
    //               gutterBottom
    //               variant="h5"
    //               component="div"
    //               sx={{
    //                 color: "#78AE37",
    //                 fontSize:  isLargeScreen ? "30px" : "20px",
    //                 fontWeight: 800,
    //                 textAlign: isMobileSmall?"center":"left",
    //                 marginTop: isMobileSmall?"0":"8px",
    //                  marginLeft: isLargeScreen
    //                   ? "75px": isLaptopSmall ? "50px"
    //                   : isTabletScreen ? "30px"
    //                   : "8px",
    //               }}
    //             >
    //               {product.title}
    //             </Typography>
    //             <Button
    //               onClick={handleButton}
    //               variant="outlined"
    //               size="small"                  
    //               sx={{
    //                 color: "#78AE37",
    //                 border: "1px solid rgba(120, 174, 55, 0.5)",
    //                 backgroundColor: "#ffffff",
    //                 fontSize: isMobileSmall? "10px":"15px",
    //                 fontWeight: 500,
    //                 fontFamily: "Roboto",
    //                 marginRight: isLargeScreen
    //                   ? "75px": isLaptopSmall ? "50px"
    //                   : isMobileSmall
    //                   ? "0px" :
    //                   isTabletScreen ? "30px"
    //                   : "8px",
    //                   '&:hover': {
    //                     borderColor: "#78AE37",
    //                   }
    //               }}
    //             >
    //               {HOMESCREEN.buttonlabel}
    //             </Button>
    //           </div>
    //           <Typography
    //             variant="body2"
    //             sx={{
    //               fontSize: "16px",
    //               fontWeight: 400,
    //               textAlign: "justify",
    //               color: "#000000",
    //               mt: 4,
    //               marginLeft: isLargeScreen
    //                 ? "75px": isLaptopSmall ? "50px"
    //                 : isMobileSmall
    //                 ? "10px":"30px",
    //               marginRight: isLargeScreen
    //                 ? "75px": isLaptopSmall ? "50px"
    //                 : isMobileSmall
    //                 ? "10px"
    //                 : "30px",
    //             }}
    //           >
    //             {expanded[product.id]
    //               ? product.description
    //               : truncateText(product.description, 100)}
    //                <Button
    //             onClick={() => handleReadMore(product.id)}
    //             variant="text"
    //             sx={{ color: "#78AE37" }}
    //           >
    //             {expanded[product.id] ? "Read Less" : "...Read More"}
    //           </Button>
    //           </Typography>
             
    //         </CardContent>
    //       </Card>
    //     ))}
    //   </>
    // );
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div 
            style={{ 
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography
                style={{
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    fontSize: isSmallScreen ? "32px" : "48px",
                    color: "#78AE37",
                    textAlign: "center"
                }}
            >
                {TEXT_MESSAGES.producttext}
            </Typography>
        </div>
    );
  };

export default TherapiesCard;
