import { baseurl } from "./constant";
let baseUrl: string;
if (process.env.REACT_APP_PORT === '4000') {
    baseUrl = baseurl.DEV_URL;
} else if (process.env.REACT_APP_PORT === '4002') {
    baseUrl = baseurl.UAT_URL;
} else if (process.env.REACT_APP_PORT === '4001') {
    baseUrl = baseurl.PROD_URL;
} else {
    baseUrl = baseurl.LOCAL_URL;
}
export default baseUrl;
