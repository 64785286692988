import React from "react";
import navbarlogo from "../assets/banner.jpg";
import { Box, CssBaseline, useTheme } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";

const Imageview: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const islargescreen = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Box sx={{ display: "flex", width: "100vw", m: 0, p: 0 }}>
      <CssBaseline />
      <div
        style={{
          marginTop: isSmallScreen ? "-120px" : "-140px",
          width: "100%",
          minWidth: "100%",
          minHeight: "200px",
          backgroundColor: "rgba(120, 174, 55, 0.1)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={navbarlogo}
          alt="Navbar Logo"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </Box>
  );
};
export default Imageview;
